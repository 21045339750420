<template>
<div class="flex-fill fill flex column row-desktop">

<div class="opt r">
    <input-select
    v-if="platforms && platforms.length"
    :icon="mobile? 'menu': ''"
    :items="platforms"
    :triggerFirstValueChanged="false"
    v-model="selectedPlatform"
    @OnValueChanged="ChangePlatform($event)"
    inputClass="secondary">
    </input-select>

    <input-field v-if="desktop" 
    ref="searchBox" 
    class="ml15"
    v-model="search" 
    @OnEnter="Search($event)" 
    placeholder="Buscar..."
    pre="search"
    cleareable
    inputClass="secondary"
    :timeout="300">
    </input-field>

    <div v-if="iap && Local.modules.module_store" :class="`ml5 relative options`" @click="$refs.iapController.Open()">
        <i :class="`icon icon-attention-bell ${iap.playBell ? 'animated heartBeat infinite cpink' : ''}`"></i>
        <div class="notif" v-show="iap.pending.length">{{iap.pending.length}}</div>
    </div>
    <div v-show="iap.scheduled.length" v-if="iap" :class="`relative options icon-datetime ml5`" @click="$refs.iapController.Open('SCHEDULED')">
        <div class="notif" v-show="iap.scheduled.length">{{iap.scheduled.length}}</div>
    </div>
    
    <i class="options icon-dots-vertical ml5" @click="Options([
        {text: showRut ? 'Ocultar RUT' : 'Mostrar RUT', function: () => { showRut = !showRut }, if: desktop, },
        {text: $refs.itemsSelection.viewGrid ? 'Cambiar a vista Lista' : 'Cambiar a vista Grilla', function: ToggleGridView, if: desktop && showInfoItem},
        {text: positionSection == 'HORIZONTAL' ? 'Agrupar por Sección' : 'Mostrar todo el Menú', function: () => {ChangePositionSection(positionSection == 'HORIZONTAL' ? 'VERTICAL' : 'HORIZONTAL')}},
        {text: showInfoItem ? 'Ocultar Imagen y Descripción' : 'Mostrar imagen y descripción', if: !$refs.itemsSelection.viewGrid, function: () => {ChangeShowItemInfo()}},
        {text: showColors ? 'Ocultar Colores' : 'Mostrar Colores', function: () => {ChangeShowColorsSectionItems()}},
        {text: 'Limpiar Campos', function: () => {Reset()}},
        {text: 'Pedidos Guardados', function: () => {OpenSavedOrders()}},
        {text: 'Guardar Pedido', function: () => {modalSaveOrder={}}, if: Util.Arr(selectedItems).length > 0},
        {text: 'Últimos Pedidos', function: () => {LoadLastOrder()}},
        {text: 'Abrir Display para Clientes', function: () => {Util.Open('/customerscreen')}, if: desktop},
    ])"></i>
</div>

<!--<iap-controller v-model="iap" ref="iapController"></iap-controller>-->

<div v-if="menu.length && desktop" class="menu-sections pa15">
    
    <div class="selectable align-center pb5 pt5"
    v-for="(section) in $refs.itemsSelection.currentMenu"
    :key="section.title"
    @click="$refs.itemsSelection.GoToSection(section.title, 0)">{{section.title}}</div>
</div>

<items-selection class="ofy flex-fill"
ref="itemsSelection"
:bodyClass="`bgbg ${mobile ? 'pl10 pr10' : ''}`"
:menu="menu"
:showSelected="false"
:showUnavailable="false"
:selectModifiers="true"
:callbackOnly="true"
@OnItemAdded="AddItem($event)"
:currentlySelected="selectedItemsID"
:disableUnavailable="true"
:showSearch="mobile"
:favorite="customer && customer.items ? customer.items : null"
:overwrites="filteredOverwrite"
:timestamp="timestamp"
:inventory="inventory"
:typeSale="sale"
:orderedItems="$refs.order? $refs.order.orderedItems : null"
:selectedPlatform="selectedPlatform"
@OnSelectionClosed="currentEditItem=null"
:positionSection="positionSection"
:showInfoItem="showInfoItem"
:showColors="showColors">
</items-selection>

<div class="bottom-info pa10"
v-if="$refs.order && mobile">
    <button @click="modalSaleData={}" class="primary purple f18 pa20">{{Util.Price($refs.order.total.totalMoney)}}</button>
    <!--
    <div class="flex column ta-center" v-if="$refs.order.discounts.totalDiscount > 0">
        <div class="secondary-text" style="color: rgba(255,255,255,0.5) !important">Subtotal</div>
        <div class="bold">{{Util.Price($refs.order.total.totalMoney)}}</div>
    </div>
    <div class="flex column ta-center" v-if="$refs.order.discounts.totalDiscount > 0">
        <div class="secondary-text" style="color: rgba(255,255,255,0.5) !important">Descuento</div>
        <div class="bold">-{{Util.Price($refs.order.discounts.totalDiscount)}}</div>
    </div>
    <div class="flex column ta-center">
        <div class="secondary-text" style="color: rgba(255,255,255,0.5) !important">Total</div>
        <div class="bold">{{Util.Price($refs.order.total.totalMoney - $refs.order.discounts.totalDiscount)}}</div>
    </div>
    -->
</div>


<div v-if="settings" v-show="desktop" class="order-menu ofy pa15" ref="desktopOrder">
    <order-form v-show="desktop || (mobile && modalSaleData)"
    ref="order"
    :baseBenefits="promotions"
    :settings="settings"
    :categories="categories"
    :tables="availableTables"
    :hideLastOrder="false"
    :hideActions="!canCreateTransactions"
    showLastOrders
    :showRut="showRut"
    @TransactionSuccess="TransactionSuccess($event)"
    @AddItem="$refs.itemsSelection.SearchAddItem($event)"
    @OnCustomerChanged="customer=$event"
    @OnSaleChanged="sale=$event"
    @OnEditItem="OpenEditItem($event)"
    @OnCustomerLastOrders="LoadCustomerOrders($event)"
    v-model="selectedItems">
    </order-form>
</div>

<modal v-model="modalSaleData" dir="up" modalClass="fill borderless" title="Datos del Pedido" hideActions>
    <div ref="mobileOrder" class="pa10"></div>
</modal>

<modal v-model="modalSaveOrder" dir="center" modalClass="sm" title="Guardar Pedido">
    <div class="section" v-if="modalSaveOrder">
        <input-field
        v-model="modalSaveOrder.id"
        label="ID"
        placeholder="Identificador del pedido">
        </input-field>
    </div>
    
    <button slot="actions" class="primary purple" @click="SaveOrder()">Guardar</button>
    
</modal>

<modal v-model="modalMyOrders" dir="right" :modalClass="['fill', 'sm right h100']" title="Pedidos Guardados" hideActions>
    <div class="pa20" v-if="modalMyOrders">
        <div class="well mb10 selectable"
        @click="LoadOrder(order.order, order.customer); modalMyOrders=null"
        v-for="(order, key) in modalMyOrders" :key="key">
            <div class="flex align-center">
                <div class="f18 bold flex-fill">{{key.replace(`order_${Local.id}_`, '')}}</div>
                <i class="options icon-trash" @click.stop="Confirm({
                    title: '¿Eliminar pedido guardado?',
                    text: 'Eliminar',
                    class: 'error-color',
                    function: () => {DeleteSavedOrder(key)}
                })"></i>
            </div>
            <div v-if="order.customer" class="secondary-text mb10 hlist">
                <span v-if="order.customer.name">{{order.customer.name}}</span>
                <span v-if="order.customer.phone">{{order.customer.phone}}</span>
                <span v-if="order.customer.address">{{order.customer.address}}</span>
            </div>
            <div class="order-item mb5" style="align-items: flex-start"
            v-for="(item, index) in order.order"
            :key="index">
                <div>{{Util.Number(item.quantity)}}</div>
                <div>
                    <div>{{item.title}}</div>
                    <ul>
                        <li :class="modifier.type" v-for="(modifier, indexMod) in Util.ModifiersList(item)" :key="indexMod">{{modifier.value}}</li>
                    </ul>
                </div>
                <!--<div>{{Util.Price(item.total_money)}}</div>-->
            </div>

        </div>
    </div>
</modal>

<modal v-model="modalLastOrders" dir="right" :modalClass="['fill', 'sm right h100']" title="Últimos Pedidos" hideActions>
    <div class="pa20" v-if="modalLastOrders">
        <div class="well mb10 selectable"
        @click="LoadOrder(order.orders, order.customer); modalLastOrders=null"
        v-for="(order, key) in modalLastOrders" :key="key">

            <div v-if="order.created_at || order.status" class="secondary-text hlist">
                <span v-if="order.created_at">{{Util.Date(order.created_at, 'dddd DD [de] MMMM HH:mm')}}</span>
                <span v-if="order.status">{{Util.TransactionStatus[order.status]}}</span>
            </div>
            <div v-if="order.customer" class="secondary-text mb10 hlist">
                <span v-if="order.customer.name">{{order.customer.name}}</span>
                <span v-if="order.customer.phone">{{order.customer.phone}}</span>
                <span v-if="order.customer.address">{{order.customer.address}}</span>
            </div>
            <div class="order-item mt5" style="align-items: flex-start"
            v-for="(item, index) in order.orders"
            :key="index"
            v-show="!item.type">
                <div>{{Util.Number(item.quantity)}}</div>
                <div>
                    <div>{{item.title}}</div>
                    <ul>
                        <li :class="modifier.type" v-for="(modifier, indexMod) in Util.ModifiersList(item)" :key="indexMod" v-show="['COMMENT', 'ADDED_AT'].indexOf(modifier.type) == -1">{{modifier.value}}</li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</modal>

<transaction-details ref="transactionDetails"></transaction-details>


</div>
</template>

<script>
export default {
    data() {
        return {
    
            connectionStatus: null,
            modal: null,
            time: 0,
            menu: [],
            promotions: [],
            modalPrinter: false,
            modalReport: false,
            reportTime: '',
            modalSettings: false,
            cashierSettings: {},
            turn: false,
            modalTurn: null,
            syncing: false,
            pendingPayment: false,
            pendingPayments: [],
            selectedPendingPayment: null,
            pendingPaymentMethod: null,
            modalPendingPayment: null,
            modalPendingPaymentTransactions: false,
            selectedItems: {},
            modalSaleData: null,
            settings: null,
            showDiscountsDetails: false,
            categories: null,
            search: '',
            lastOrder: null,
            modalSaveOrder: null,
            customer: null,
            modalMyOrders: null,
            
            tables: null,
            defaultDeliveryCosts: [],
            inventory: null,

            //@data modalRejectMessage <Object> modal para escribir mensaje customizado al rechazar pedido
            iap: null,

            menuOverwrites: null,
            timestamp: null,
            timer: null,

            currentEditItem: null,
            sale: 'SERVE',
            modalLastOrders: null,

            //Muestra/oculta rut
            showRut : false,
            typeMenu: 'SCHEDULE',
            dictPlatforms: {
                SCHEDULE: 'Ninguno',
                pedidos_ya: 'PedidosYa',
                uber_eats: 'Uber Eats',
            },
            selectedPlatform: null,
            positionSection: 'HORIZONTAL',
            showInfoItem: true,
            showColors : false,
        }
    },
    mounted() {

        localStorage.local = JSON.stringify({
            name: this.Local.name,
            logotype: this.img('logos/'+this.Local.logotype)
        });

        this.positionSection = localStorage.getItem('positionSection-pos') || 'HORIZONTAL';
        this.showInfoItem = localStorage.getItem('showInfoItem-pos') == 'false' ? false : true;
        this.showColors = localStorage.getItem('showColors-pos') == 'true' ? true : false;      

        //Según la variable en LS, se asgina a showRut
        localStorage.getItem('rut') ? this.showRut = true : this.showRut = false; 

        if(localStorage.getItem('posGridMenu')) this.$refs.itemsSelection.ToggleView();

        NativeStorage.getItem(`lastOrder_${this.Local.id}`, (val) => {
            this.lastOrder = val;
        });
        this.UpdateMenu(false);
        this.UpdateInventory();
        
        this.$root.$on('OnDesktop', () => {
            this.modalSaleData = null;
        });

        this.timer = setInterval(() => {
            if(this.timestamp) this.timestamp++;
            var transaction = this.$refs.order ? this.$refs.order.transaction : null;
            
            var transactionDetails = this.$refs.transactionDetails;
            
            if(transactionDetails.modalTicket)
            {
                var ret = JSON.parse(localStorage.customerscreen);
                if(!ret) ret = {};
                ret.bankPaymentData = transactionDetails.modalSendBankData;
                ret.onlinePaymentData = transactionDetails.modalSendOnlinePaymentLink ? {code: transactionDetails.transaction.code} : null;
                localStorage.customerscreen = JSON.stringify(ret);
            }
            else
            {
                if(typeof transaction == 'string')
                {
                    localStorage.customerscreen = JSON.stringify(null);
                }
                else if(this.$refs.order)
                {
                    var ret = {
                        items: this.selectedItems,
                        discount: this.$refs.order.discounts.totalDiscount,
                        round: this.$refs.order.roundTotal,
                        delivery: this.$refs.order.deliveryCost,
                        subtotal: this.$refs.order.total.order,
                        total: this.$refs.order.totalMoney,
                        tips: this.$refs.order.totalTips,
                        total_tips: this.$refs.order.totalMoney + this.$refs.order.totalTips,
                    }
                    localStorage.customerscreen = JSON.stringify(ret);
                }
            }

        }, 1000);
    },
    methods: {
        UpdateMenu(assync) 
        {
            if(!assync) this.$root.$emit('Loading');
            axios.get(`/sale/${this.Local.id}`).then(res => {
                if(!assync) this.$root.$emit('Response', res);
                this.menu = res.data.menu;
                //this.promotions = res.data.promotions;

                /*
                var deliveryCosts = [];
                res.data.settings.delivery_costs.forEach(e => {
                    deliveryCosts.push({text: (e.title ? e.title+': ' : '') + Util.Price(e.cost), value: e.cost, name: e.title});
                });
                res.data.settings.delivery_costs = deliveryCosts;
                */

                this.settings = res.data.settings;
                this.categories = res.data.categories;
                this.tables = res.data.tables;
                if(res.data.delivery_costs) this.defaultDeliveryCosts = res.data.delivery_costs;
                this.menuOverwrites = res.data.overwrites;

                let platform = localStorage.getItem('platform') || 'SCHEDULE';
                let isValidPlatform = this.platforms.some(p => p.value === platform);

                this.typeMenu = this.selectedPlatform = isValidPlatform ? platform : 'SCHEDULE';

                this.timestamp = res.data.timestamp;

            }).catch(err => {
                if(!assync) this.$root.$emit('Response', err);
            });
        },
        UpdateInventory() {
            if(!this.Local.modules.module_inventory) return;
            axios.get(`/inventory/${this.Local.id}/current`).then(res => {
                this.inventory = res.data;
            }).catch(err => {
                console.log('inventory fetch error');
            });
        },
        Reset(preserveFields = false) {
            this.selectedItems = {};
            if(!preserveFields) this.$refs.order.Reset();
        },
        AddItem(item, quantity)
        {
            if(this.currentEditItem)
            {
                this.$delete(this.selectedItems, this.currentEditItem.key);
                this.currentEditItem = null;
            }

            var scanned = item.scanned;
            this.$delete(item, 'scanned');

            this.$nextTick(() => {

                var key = item.key;
                if(this.selectedItems[key])
                {
                    var itemData = item;
                    let quantity = item.quantity;

                    if(item.max_quantity_enabled){
                        if(quantity > item.max_quantity) return this.Info(`La cantidad máxima permitida de ${item.title} es ${item.max_quantity}`);
                        if(this.orderGroupById && quantity + this.orderGroupById[item.id] > item.max_quantity) return this.Info(`La cantidad máxima permitida de ${item.title} es ${item.max_quantity}`);
                    }

                    itemData.quantity += this.selectedItems[key].quantity;
                    itemData.total_money += this.selectedItems[key].total_money;
                    this.$set(this.selectedItems, key, itemData)
                }
                else
                {
                    if(item.max_quantity_enabled && this.orderGroupById && item.quantity + this.orderGroupById[item.id] > item.max_quantity) return this.Info(`La cantidad máxima permitida de ${item.title} es ${item.max_quantity}`);
                    this.$set(this.selectedItems, key, item);
                }

                if(this.desktop && !scanned)
                {
                    var el = this.$refs.searchBox.Focus();
                }

                if(scanned) 
                {
                    this.$refs.searchBox.Focus(false);
                    setTimeout(() => {
                        this.search = '';
                    }, 100);
                    
                }

            })

        },
        OpenEditItem(item)
        {
            this.currentEditItem = item;
            this.$refs.itemsSelection.SearchAddItem(item, true);
        },
        TransactionSuccess(transaction) 
        {
            this.modalSaleData = false;
            if(transaction.orders)
            {
                //this.$refs.modalTicket.Open(transaction);
                this.$refs.transactionDetails.OpenTicket(transaction);

                NativeStorage.setItem(`lastOrder_${this.Local.id}`, transaction.orders, () => {
                    this.lastOrder = transaction.orders;
                });

                this.Util.NotifyService('transaction', {data: transaction});
            }

        },
        LoadLastOrder() 
        {
            //if(this.lastOrder) this.LoadOrder(this.lastOrder);
            this.Loading();
            axios.get(`/transactions/${this.Local.id}/last`).then(res => {
                this.Response(res);
                this.modalLastOrders = res.data;
            }).catch(err => {
                this.Response(err);
            });
        },
        LoadOrder(order, customer) 
        {
            var count = 0;
            for(var i = 0; i < order.length; i++)
            {
                if(!order[i].type) 
                {
                    if(!this.$refs.itemsSelection.SearchAddItem(order[i], false, false, true)) count++; 
                }
            }

            if(customer)
            {
                this.$refs.order.customer = customer;
            }

            if(count) this.Info(`${count} items no se agregaron por no disponibilidad o cambios en el menú`);
        },
        SaveOrder() 
        {
            if(!this.modalSaveOrder.id) return this.Info('ID inválido');

            var data = {
                customer: this.$refs.order.customer,
                order: Object.values(this.selectedItems)
            };

            NativeStorage.setItem(`order_${this.Local.id}_${this.modalSaveOrder.id}`, data, () => {
                this.Info('Pedido guardado');
                this.modalSaveOrder = null;
            });
        },
        Search(search) 
        {
            NativeStorage.getItem(`order_${this.Local.id}_${search}`, (val) => {
                console.log(`order_${this.Local.id}_${search} found`);
                if(Array.isArray(val)) val = {order: val, customer: null};
                this.LoadOrder(val.order, val.customer);
            }, () => {
                console.log(`order_${this.Local.id}_${search} not found, click first item`);
                this.$refs.itemsSelection.ClickFirst()
            })
            this.search='';
        },
        async OpenSavedOrders()
        {

            function GetKeys()
            {
                return new Promise((resolve, reject) => {
                    NativeStorage.keys((keys) => {
                        resolve(keys);
                    });
                })
            }
            
            function GetItem(key)
            {
                return new Promise((resolve, reject) => {
                    NativeStorage.getItem(key, val => {
                        resolve(val);
                    }, err => {
                        resolve(null);
                    });
                });
            }

            this.Loading();
            var keys = await GetKeys();
            var ret = {};
            for(var i = 0; i < keys.length; i++)
            {
                if(keys[i].indexOf(`order_${this.Local.id}_`) === 0)
                {
                    var data = await GetItem(keys[i]);
                    if(Array.isArray(data)) data = {customer: null, order: data};
                    ret[keys[i]] = data;
                }
            }

            if(!Object.values(ret).length)
                return this.Info('No hay pedidos guardados');

            this.modalMyOrders = ret;
            this.Response({error: false});
        },
        DeleteSavedOrder(key)
        {
            NativeStorage.remove(key);
            this.modalMyOrders = null;
            this.Info('Pedido eliminado');
        },
        LoadCustomerOrders(phone) {
            this.Loading();
            axios.get(`/transactions/${this.Local.id}/customer/${phone}/last`).then(res => {
                this.Response(res);
                this.modalLastOrders = res.data;
            }).catch(err => {
                this.Response(err);
            });
        },
        IsShownRut(key){
            if(this.showRut === false) localStorage.removeItem(key);
            else localStorage.setItem(key, this.showRut);
        },
        ToggleGridView(val) {
            this.$refs.itemsSelection.ToggleView();
            if(this.$refs.itemsSelection.viewGrid) localStorage.setItem('posGridMenu', true);
            else localStorage.removeItem('posGridMenu');
        },
        ChangePlatform(platform){
            this.typeMenu = this.selectedPlatform;
            this.selectedPlatform = platform;
            localStorage.setItem('platform', platform);

            this.$nextTick( () => {
                let order = this.$refs.order? this.$refs.order.orderedItems : null;

                if(!order) return;

                this.Reset(true);

                let count = 0;

                order.forEach(item => {
                    if(!this.$refs.itemsSelection.SearchAddItem(item, false, false, true)) count++; 
                });

                if(count) this.Info(`${count} items no se agregaron por no disponibilidad o cambios en el menú`);
            });
        },
        ChangePositionSection(position){
            this.positionSection = position
            this.$refs.itemsSelection.selectedSection = null
            if(position == 'VERTICAL') this.$refs.itemsSelection.BackToSections()
            localStorage.setItem('positionSection-pos', position)
        },
        ChangeShowItemInfo(){
            this.showInfoItem = !this.showInfoItem
            localStorage.setItem('showInfoItem-pos', this.showInfoItem)
        },
        ChangeShowColorsSectionItems(){
            this.showColors = !this.showColors
            localStorage.setItem('showColors-pos', this.showColors)
        },
    },
    computed: {
        selectedItemsID() {
            var ret = {};
            for(var key in this.selectedItems)
            {
                var id = this.selectedItems[key].id;
                if(ret[id]) ret[id] += this.selectedItems[key].quantity;
                else ret[id] = this.selectedItems[key].quantity;
            }
            return ret;
        },
        availableTables() {
            var ret = [{text: 'Sin seleccionar', value: null}];
            if(!this.tables) return ret;
            
            for(var key in this.tables)
            {
                var table = this.tables[key];
                if(table.available && !table.id_transaction  && !table.reserved)
                {
                    ret.push({
                        text: table.name,
                        value: table.name
                    });
                }
            }

            return ret;
        },
        canCreateTransactions() {
            if(!this.Auth) return false;
            if(this.Auth.role.indexOf('admin') > -1) return true;
            return !!(!this.Auth.restrictions.transactions_turn_only || this.Turn);
        },
        orderGroupById(){
            if(!this.selectedItems || !Object.keys(this.selectedItems).length) return;
            const groupedData = {};

            for(let index in this.selectedItems){
                if(this.selectedItems.hasOwnProperty(index)){
                    const tmpItem = this.selectedItems[index];
                    const id = tmpItem.id;
                    const quantity = tmpItem.quantity;

                    if (!groupedData[id]) {
                        groupedData[id] = 0;
                    }
                    
                    groupedData[id] += quantity;
                }
            }
            return groupedData;
        },
        filteredOverwrite(){
            if(!this.menuOverwrites) return;
            let overwrites = Util.Copy(this.menuOverwrites);
            const filtered = overwrites.filter(overwrite => overwrite.type  == this.typeMenu);
            return filtered;
        },
        platforms(){
            if(!this.menuOverwrites) return [];
            let overwrites = Util.Copy(this.menuOverwrites);

            const uniqueTypes = [...new Set(overwrites.map(overwrite => overwrite.type))];

            if(!uniqueTypes.length || (uniqueTypes.length == 1 && uniqueTypes[0] == "SCHEDULE")) return []; //Sí sólo tiene horarios SCHEDULE

            const index = uniqueTypes.indexOf("SCHEDULE");
            if(index !== -1) uniqueTypes.splice(index, 1);

            uniqueTypes.unshift("SCHEDULE");

            const formattedTypes = uniqueTypes.map(type => {
                return {
                    value: type,
                    text: this.dictPlatforms[type]? this.dictPlatforms[type] : type
                };
            });

            return formattedTypes;
        }
    },
    watch: {
        modalSaleData: function(n, o) {
            if(n) 
            {
                this.$nextTick(() => {
                    this.$refs.mobileOrder.appendChild(this.$refs.order.$el);
                });
            }
            else
            {
                console.log('modal closed');
                this.$nextTick(() => {
                    this.$refs.desktopOrder.appendChild(this.$refs.order.$el);
                });
            }
        },
        search: function(n, o) {
            /*
            if(n.length == 4 && .isNumber(parseInt(n)))
            {  
                var section = parseInt(n.substring(0, 2))-1;
                var item = parseInt(n.substring(2, 4))-1;
                if(section >= 0 && item >= 0 && section < this.menu.length)
                {
                    if(item < this.menu[section].items.length)
                    {
                        this.$refs.itemsSelection.Search('*'+this.menu[section].items[item].id);
                    }
                }
            }
            else
            */
            this.$refs.itemsSelection.Search(n);
        },
        showRut : function(n, o){
            console.log(n, o);
            if(this.showRut === false) localStorage.removeItem('rut');
            else localStorage.setItem('rut', this.showRut); 
        }
    },
    sockets: {
        connect()
        {
            this.UpdateMenu(true);
            this.UpdateInventory();
        },
        MENU_UPDATED() {
            this.Toast('Menu actualizado');
            this.UpdateMenu(true);
        },
        INVENTORY_UPDATED() {
            console.log('event inventory updated');
            this.UpdateInventory();
        },
        TABLES_UPDATED() {
            console.log('event tables updated');
            this.UpdateMenu(true);
        },
        /*
        UPDATE_TABLES(data)
        {
            this.tables = JSON.parse(data.tables);
        }
        */
    },
    beforeDestroy() {
        document.getElementById('header-bar').classList.remove('alert-header');
        if(this.timer) clearInterval(this.timer);
    }
}
</script>

<style lang="scss">
.cashier-turn {
    background-color: var(--white);
    color: #777;
    padding: 5px;
    border-radius: 5px;
    font-size: 10px;
}
.transaction-ticket {
    width: 340px !important;
    background-color: var(--white);
    box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.1);
    border-radius: 10px;
}

.menu-sections {
    overflow-y: auto; 
    min-width: 150px; 
    max-width: 150px; 
    font-size: 12px;

    div {
        color: var(--gray-1);
        &:hover {
            color: var(--black);
        }
    }

}

.order-menu {
    min-width: 350px; 
    max-width: 350px; 
    border-left: 1px solid var(--gray-2);
    background-color: var(--white);
}

@media (max-width: 820px) {
    .menu-sections {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        overflow-x: auto;
        overflow-y: clip;
        flex-shrink: 0;
    }
    .menu-sections .section {
        display: flex;
        padding: 0px 10px;
        overflow-x: auto;
    }
    .menu-sections .section .input-style {
        flex-shrink: 0;
        margin: 0px;
        padding: 0px 10px;
        min-height: 50px;
        max-height: 50px;
        border-bottom: none;
    }
}

.alert-screen {
    animation: anim-alert-screen 700ms ease-in-out 0s infinite alternate;
}
@keyframes anim-alert-screen {
    0% {
        background-color: var(--white);
    }
    100% {
        background-color: rgb(0, 181, 255);
    }
}

.alert-header {
    animation: anim-alert-header 700ms ease-in-out 0s infinite alternate;
}
@keyframes anim-alert-header {
    0% {
        background-color: #3b3b3b;
    }
    100% {
        background-color: rgb(0, 181, 255);
    }
}

.dark {
    .menu-sections {
        div {
            color: var(--dark-fc-2);
            &:hover {
                color: var(--dark-fc-1);
            }
        }
    }
    .order-menu {
        border-left: none;
        background-color: var(--dark-bg-1);
    }
}

.hlist {
    *:not(:first-child)::before {
        content: "•";
        margin: 0px 5px;
    }
}

</style>
