<template>
<div class="flex column ofy">

	<!--
	<div class="section pa10-desktop" v-if="showSearch">
		<div class="flex">
			<input-field class="fill"
			v-model="search"
			label="Buscar">
				<i v-show="search" class="icon icon-cross selectable c4" @click="search=''" slot="after"></i>
			</input-field>
			<i :class="`ml10 hide-mobile options icon-${viewGrid ? 'list' : 'grid'}`" @click="viewGrid=!viewGrid"></i>
		</div>
	</div>
	-->
	<div v-if="currentMenu && currentMenu.length && showSearch" class="menu-sections-toolbar bb">
		<div class="flex pa10">
			<div class="flex align-center flex-fill" v-if="selectedSection && positionSection == 'VERTICAL'">
				<button class="mr5 options" @click="BackToSections()" >
					<i class="icon icon-left-arrow c4 selectable"></i>
				</button>
				<div class="f18 mr5" v-if="!mobile || (mobile && !$refs.searchItemSelection.isFocused)">
					{{ selectedSection }}
				</div>
			</div>
			<input-field v-model="search" @Enter="Search()"
			ref="searchItemSelection"
			placeholder="Buscar"
			pre="search"
			cleareable
			:icon="mobile && positionSection != 'VERTICAL' ? 'search' : ''"
			class="unshrink"
			inputClass="secondary"
			:timeout="300">
			</input-field>
			<div :style="showColors && section.color ? `color: ${section.color}` : null" class="selectable unshrink section-entry"
			v-for="(section) in currentMenu"
			:key="section.title"
			v-show="section.items.length && positionSection != 'VERTICAL'"
			@click="GoToSection(section.title)">{{section.title}}</div>
		</div>
	</div>
	
	<div class="section" v-if="showSelected">
		<div class="flex align-center mb10">
			<div class="f18">Seleccionados</div>
			<div class="counter ml5">{{selectedInfo.count}}</div>
		</div>
		<div v-for="(item, index) in selecteditems" :key="index" class="well mb10 flex align-center">

			<div class="flex-fill bold">{{item.title}}</div>
			<div>
				<i class="icon icon-minus c4 selectable mr10" @click="item.quantity > 1 ? $set(item, 'quantity', item.quantity-1) : $delete(selecteditems, index)"></i>
				<span class="ml5 mr5 bold">{{item.quantity}}</span>
				<i class="icon icon-plus c4 selectable ml10" @click="$set(item, 'quantity', item.quantity+1)"></i>
			</div>

		</div>
	</div>

	<slot></slot>

	<div v-if="!visibleItems.length && selectedSection" class="secondary-text ta-center mt10">No hay items</div>

	<div :class="`ofy ${bodyClass}`">
		<div class="flex column mb20 bginherit" v-if="positionSection && positionSection == 'VERTICAL' && !selectedSection && visibleItems.length == 0" id="sectionSections" ref="sectionSections">
			<div class="pa5 mt5">
				<div :style="showColors && section.color ? `color: ${section.color}` : null" class="selectable item-list section relative bold"
				v-for="(section) in currentMenu"
				:key="section.title"
				v-show="section.items.length"
				@click="GoToSection(section.title)"> 
					<div>{{section.title}} <span class="secondary-text">({{ section.items.length }})</span></div>
				</div>
			</div>
		</div> 

		<div class="sticky flex column bginherit" v-else-if="positionSection == 'VERTICAL' && selectedSection && !showSearch">
			<div class="flex align-center f18 pb10 pt10 bginherit">
				<i class="options icon-left-arrow mr5" @click="selectedSection = null"></i>
				<div>{{ selectedSection }}</div>
			</div>
		</div> 


		<div class="mb20 bginherit"
		v-for="(section) in visibleItems"
		:id="`section-${section.title}`"
		:key="`section-${section.title}`"
		:ref="`section-${section.title}`">
			<div class="sticky flex align-center f18 pa10 bginherit" v-if="positionSection != 'VERTICAL' || !selectedSection">
				<div :style="showColors && section.color ? `color: ${section.color}` : null">{{section.title}}</div>
			</div>
			<div :class="`flex ${viewGrid ? 'row' : 'column'} pa5`" style="flex-wrap: wrap">
				<div v-for="(item, index2) in section.items"
				:key="`${index2}${item.title}`"
				:class="`section relative ${canClickItems ? 'selectable' : ''} ${viewGrid ? 'item-grid' : 'item-list'} ${!disableUnavailable || !item.unavailable ? '' : 'item-unavailable'}`"
				:title="item.description?item.description:item.title"
				@click="ClickItem(item, section.title)">
					<div class="relative" style="background-color: inherit" v-if="showInfoItem">
						<div class="mr10 item-image unshrink">
							<img v-if="item.image && item.image !== 'NO_IMAGE.png'" :src="img(`products/s${item.image}`)" class="fill">
							<img v-else :src="img(`products/${dark ? 'sDARK_NO_IMAGE.png' : 'sNO_IMAGE.png'}`)" class="fill">
						</div>
						<div class="secondary-text tag-reservation justify-center" :style="`${viewGrid ? 'bottom: 5px; left: 16px;' : 'left: -15px; bottom: -22px;'} padding: 5px !important;`"
						v-if="item && (item.price_old || item.price_overwrite || item.unavailable_overwrite)"  title="Se aplica una modificación de horario/plataforma">
							<i class="icon icon-circle-exclamation cyellow"></i> 
						</div>
						<div class="secondary-text tag-reservation flex align-center justify-center" v-if="item.anticipation"  title="Horas de preparación del pedido">
							<i class="icon icon-clock"></i> 
							<span class="unshrink" style="margin-left: 3px">{{ item.anticipation_time }} Hrs</span>
						</div>
 					</div>
					<div class="flex-fill flex column">
						<span class="bold" style="max-height: 25px; overflow: hidden;" :style="showColors ? section.color ? `color: ${section.color}` : item.color ? `color: ${item.color}` : null : null ">{{item.title}}</span>
						<div v-if="!viewGrid && showInfoItem" class="secondary-text" style="white-space: pre-line">{{item.description}}</div>
					</div>
					<div class="flex justify-center align-center ml5" v-if="!disableUnavailable || !item.unavailable">
						<span class="cred mr5 line-through" v-if="item.price_reduction">{{Util.Price(item.price_old)}}</span>
						<span class="item-tag mr5" v-if="item.price_label">{{item.price_label}}</span>
						<span class="bold">{{Util.Price(item.price)}}</span>
					</div>
					<div v-else class="secondary-text ta-center" style="font-size: 10px">No disponible</div>
					
					<div class="selection-counter" v-if="(currentlySelected && currentlySelected[item.id]) || selecteditems[item.id]">
						<div v-if="selecteditems[item.id]">{{Util.Number(selecteditems[item.id].quantity)}}</div>
						<div v-if="currentlySelected && currentlySelected[item.id]">{{Util.Number(currentlySelected[item.id])}}</div>
					</div>
					
					<i class="icon icon-heart fav-item" v-if="favorite && favorite[item.title] > 1"></i>
				</div>
			</div>
		</div>
	</div>


	<modal v-model="modalModifiers" dir="right" :modalClass="['fill', 'right lg h100']" :title="modalModifiers ? modalModifiers.title : ''">
		<input-field slot="options" class="mr10" inputClass="secondary"
		v-model="searchModifier"
		placeholder="Buscar..."
		pre="search"
		cleareable
		:timeout="300"
		:icon="mobile ? 'search' : ''"
		useHeader>
		</input-field>
		<div class="ofy flex" v-if="modalModifiers">
			<div class="pa20 ofy flex-fill pt0" ref="containerModifiers">
				
				<div class="flex column entry"
				v-for="(group, index) in modalModifiers.modifiers"
				:key="index"
				ref="modifierGroups"
				v-show="modifiersFilter === null || modifiersFilter[group.title]">
					<div @click="group.expand ? modalModifiersExpand=group : null" :class="`${group.expand ? 'modifiers-group-expand' : ''} bgbg1 flex sticky pb10 pt10 ${ModifierGroupConditions(group) ? '' : 'cred'}`">
						<div class="flex column flex-fill">
							<div class="flex align-center">
								<div class="bold mr5">{{group.title}}</div>
								<div class="secondary-text">{{Util.ModifierGroupLimits(group)}}</div>
								<div class="flex-fill"></div>
								<!--<i v-if="group.expand" class="options icon icon-arrow-right"></i>-->
								<button v-if="group.expand" :class="`${ModifierGroupConditions(group) ? 'secondary purple' : 'secondary error-color'} icon`"><i v-if="group.expand" class="icon icon-arrow-right"></i></button>
							</div>
							<div class="flex column" v-if="group.expand">

								<div v-for="(mod, i) in group.modifiers" :key="i" v-show="mod.selected || mod.count" class="flex mt5 cselected">
									<span v-if="mod.count" class="bold mr10 ml5">{{mod.count}}</span>
									<span>{{mod.title}}</span>
									<span v-if="mod.replacement"><span class="ml5 mr5">⇄</span>{{mod.replacement.title}}</span>
								</div>
							</div>
						</div>
					</div>
					<div :class="`pb5 pt5 selectable item-modifier ${!disableUnavailable || !modifier.unavailable ? 'available' : 'unavailable'} ${modifier.selected || modifier.count > 0 ? 'selected' : 'unselected'}`"
					v-for="(modifier, index2) in group.expand ? [] : group.modifiers"
					:key="index2"
					@click="!group.multi_select ? SelectModifier(modifier, group) : AddModifier(modifier, group, 1)"
					v-show="modifiersFilter === null || (modifiersFilter[group.title] && modifiersFilter[group.title][modifier.title])">
						<div class="fill flex">
							<div v-if="group.multi_select" class="mr10 unshrink flex align-center" style="user-select: none">
								<button :disabled="disableUnavailable && modifier.unavailable" @click.stop="AddModifier(modifier, group, -1)" class="icon secondary sm"><i class="icon icon-minus"></i></button>
								<span style="display:inline-block; width: 40px;" class="bold ta-center count">{{modifier.count ? modifier.count : '0'}}</span>
								<button :disabled="disableUnavailable && modifier.unavailable" @click.stop="AddModifier(modifier, group, 1)" class="icon secondary sm"><i class="icon icon-plus"></i></button>
							</div>
							<div v-else :class="`check ${modifier.selected?'selected':''} ${disableUnavailable && modifier.unavailable?'unavailable':''} mr10`"></div>
							<div class="flex column flex-fill">
								<div class="title">{{modifier.title}} <span v-if="modifier.replacement"> ⇄ {{modifier.replacement.title}}</span></div>
								<div class="description secondary-text">{{modifier.description}}</div>
							</div>
							<div v-if="!modifier.replacement">
								<div v-if="!disableUnavailable || !modifier.unavailable">
									<div class="flex align-center">
										<div class="secondary-text mr5 " v-if="modifier && (modifier.price_old || modifier.price_overwrite)"  title="Se aplica una modificación de horario/plataforma">
											<i class="icon icon-circle-exclamation cyellow "></i> 
										</div>
										<span class="mr5 line-through cred" v-if="modifier.price_reduction">{{Util.Price(modifier.price_old)}}</span>
										<span class="item-tag mr5" v-if="modifier.price_label">{{modifier.price_label}}</span>
										<span>{{Util.Price(modifier.price)}}</span>
									</div>
									<!-- <div v-else class="secondary-text">Sin Costo</div> -->
								</div>
								<div v-else class="secondary-text"><i v-if="!modifier.unavailable_overwrite" class="icon icon-circle-exclamation cyellow mr5" title="Se aplica una modificación de horario/plataforma"></i> {{modifier.unavailable_stock ? 'Sin stock' : 'No disponible'}}</div>
							</div>
							<div v-if="modifier.replacement" class="ml10">{{Util.Price(modifier.price + modifier.replacement.price)}}</div>
						</div>
					</div>

					

				</div>

			</div>
			<div v-if="desktop" class="flex column pa10 ofy bl" style="width: 200px">
				<div class="bold">{{modalModifiers.title}}</div>
				<ul>
					<li :class="modifier.type" v-for="(modifier, indexMod) in selectedModifiers.display" :key="indexMod">{{modifier.value}}</li>
				</ul>
			</div>
		</div>
		
		<div slot="actions" class="fill flex column align-center" v-if="modalModifiers && !modalCombo">
			<div class="mb10 w100" v-if="modalModifiers.comment_required">
				{{ modalModifiers.comment_instructions }}
			</div>

			<input-field class="fill mb10" 
			v-if="!hideComment"
			v-model="modalModifiers.comment"
			:placeholder="`Comentario ${modalModifiers.comment_required ? '(Obligatorio)' : '(Opcional)'}`">
			</input-field>

			<div class="fill flex align-center">
				<div v-if="multiSelection" class="flex flex-fill align-center">
					
					<v-popover :trigger="modalModifiers.float_quantity ? 'hover' : ''" placement="top" popoverBaseClass="float-quantity left tooltip input-popover mb">
						<i @click="AddQuantity(-1)" class="options icon-minus mr5"></i>
						<div slot="popover">
							<button @click="AddQuantity(-0.05)">-0,05</button>
							<button @click="AddQuantity(-0.1)">-0,1</button>
							<button @click="AddQuantity(-0.25)">-0,25</button>
							<button @click="AddQuantity(-0.5)">-0,5</button>
						</div>
					</v-popover>
					
					<input-field class="flex-fill"
					v-model="quantity"
					type="float+"
					:suffix="typeof modalModifiers.float_quantity_measure == 'string' ? ` (${modalModifiers.float_quantity_measure})` : ''"
					@Enter="AddItem(modalModifiers, quantity)">
					</input-field>
					
					<v-popover :trigger="modalModifiers.float_quantity ? 'hover' : ''" placement="top" popoverBaseClass="float-quantity right tooltip input-popover mb">
						<i @click="AddQuantity(1)" class="options icon-plus ml5"></i>
						<div slot="popover">
							<button @click="AddQuantity(0.05)" class="options quantity-description ml5">+0,05</button>
							<button @click="AddQuantity(0.1)" class="options quantity-description ml5">+0,1</button>
							<button @click="AddQuantity(0.25)" class="options quantity-description ml5">+0,25</button>
							<button @click="AddQuantity(0.5)" class="options quantity-description ml5">+0,5</button>
						</div>
					</v-popover>
				</div>
				<div v-else class="flex-fill"></div>
				<button :class="`${ModifiersConditions() ? 'selected-color' : 'error-color'} primary ml20`" @click="AddItem(modalModifiers, quantity)">Aceptar <span class="ml5" v-if="total > 0">{{Util.Price(total)}}</span></button>
			</div>
		</div>
		<div slot="actions" v-if="modalModifiers && modalCombo" class="flex fill">
			<input-field class="fill mr10"
			v-if="!hideComment"
			v-model="modalModifiers.comment"
			placeholder="Comentario (Opcional)">
			</input-field>
			<button :class="`${ModifiersConditions() ? 'purple' : 'error-color'} primary`" @click="modalModifiers=null">Aceptar <span class="ml5" v-if="selectedModifiers.total_money > 0">{{Util.Price(selectedModifiers.total_money)}}</span></button>
		</div>
	</modal>

	<modal v-model="modalQuantity" dir="center" :modalClass="['', 'sm']" :title="modalQuantity ? modalQuantity.title : ''">
		<div class="section" v-if="modalQuantity">
			<div class="mb10" v-if="modalQuantity.comment_required">{{ modalQuantity.comment_instructions }}</div>
			<input-field class="flex-fill mb20"
			v-if="!hideComment"
			label="Comentario"
			v-model="modalQuantity.comment"
			:placeholder="modalQuantity.comment_required ? '(Obligatorio)' : '(Opcional)'">
			</input-field>

			<div v-if="multiSelection">

				<div class="bold mb10">Cantidad</div>

				<div class="flex align-center">
					
					<v-popover :trigger="modalQuantity.float_quantity ? 'hover' : ''" placement="top" popoverBaseClass="float-quantity left tooltip input-popover">
						<i @click="AddQuantity(-1)" class="options icon-minus mr5"></i>
						<div slot="popover">
							<button @click="AddQuantity(-0.05)">-0,05</button>
							<button @click="AddQuantity(-0.1)">-0,1</button>
							<button @click="AddQuantity(-0.25)">-0,25</button>
							<button @click="AddQuantity(-0.5)" >-0,5</button>
						</div>
					</v-popover>

					<input-field class="flex-fill"
					v-model="quantity"
					type="float+"
					:suffix="typeof modalQuantity.float_quantity_measure == 'string' ? ` (${modalQuantity.float_quantity_measure})` : ''"
					@Enter="modalQuantity.anticipation? confirmAnticipation = {item: modalQuantity, quantity} : AddItem(modalQuantity, quantity)">
					</input-field>
					
					<v-popover :trigger="modalQuantity.float_quantity ? 'hover' : ''" placement="top" popoverBaseClass="float-quantity right tooltip input-popover">
						<i @click="AddQuantity(1)" class="options icon-plus ml5"></i>
						<div slot="popover">
							<button @click="AddQuantity(0.05)" class="options quantity-description ml5">+0,05</button>
							<button @click="AddQuantity(0.1)" class="options quantity-description ml5">+0,1</button>
							<button @click="AddQuantity(0.25)" class="options quantity-description ml5">+0,25</button>
							<button @click="AddQuantity(0.5)" class="options quantity-description ml5">+0,5</button>
						</div>
					</v-popover>
				</div>

			</div>

		</div>
		
		<button slot="actions" :class="`primary ${total <= 0 ? 'error-color' : 'selected-color'}`" @click="modalQuantity.anticipation? confirmAnticipation = {item: modalQuantity, quantity} : AddItem(modalQuantity, quantity)">Aceptar <span class="ml5" v-if="total > 0">{{Util.Price(total)}}</span></button>
		
	</modal>

	<modal v-model="modalModifiersExpand" :title="modalModifiersExpand ? modalModifiersExpand.title : 'Selección de Modificadores'" dir="right" :modalClass="['fill', 'sm h100 right']">
		
		<div class="secondary-text" slot="header" style="margin-top: -8px" v-if="modalModifiersExpand && Util.ModifierGroupLimits(modalModifiersExpand)">{{Util.ModifierGroupLimits(modalModifiersExpand).replace('(', '').replace(')', '')}}</div>

		<div class="section" v-if="modalModifiersExpand">
			
				<!--
				<div :class="`sticky bgw pb10 pt10 mb20 ${ModifierGroupConditions(modalModifiersExpand) ? '' : 'cred'}`">
					<div class="bold">{{modalModifiersExpand.title}}</div>
					<div class="secondary-text">{{Util.ModifierGroupLimits(modalModifiersExpand)}}</div>
				</div>
				-->

				<div :class="`well pa10 mb10 selectable item-modifier  ${!disableUnavailable || !modifier.unavailable ? 'available' : 'unavailable'} ${modifier.selected || modifier.count > 0 ? 'selected' : 'unselected'}`"
				v-for="(modifier, index2) in filteredModifiers"
				:key="index2"
				@click="!modalModifiersExpand.multi_select ? SelectModifier(modifier, modalModifiersExpand) : AddModifier(modifier, modalModifiersExpand, 1)"
				v-show="modifiersFilter === null || (modifiersFilter[modalModifiersExpand.title] && modifiersFilter[modalModifiersExpand.title][modifier.title])">
					<div class="fill flex">
						<div v-if="!modalModifiersExpand.multi_select" :class="`check ${modifier.selected?'selected':''} ${disableUnavailable && modifier.unavailable?'unavailable':''} mr10`"></div>
						<div class="flex column flex-fill">
							<div class="flex mb5">
								<div class="flex-fill title">{{modifier.title}}<span v-if="modifier.replacement"><i class="icon icon-exchange ml5 mr5"></i> {{modifier.replacement.title}}</span> </div>
								<div v-if="!modifier.replacement">
									<div v-if="!disableUnavailable || !modifier.unavailable" class="flex">
										<span class="cred mr5 line-through" v-if="modifier.price_reduction">{{Util.Price(modifier.price_old)}}</span>
										<span class="item-tag mr5" v-if="modifier.price_label">{{modifier.price_label}}</span>
										<div>{{Util.Price(modifier.price)}}</div>
										<!--<div v-else class="secondary-text">Sin Costo</div>-->
									</div>
									<div v-else class="secondary-text">No disponible</div>
								</div>
								<div v-if="modifier.replacement" class="ml10">{{Util.Price(modifier.price + modifier.replacement.price)}}</div>
							</div>
							<div class="secondary-text description">{{modifier.description}}</div>
						</div>
						
					</div>
					<div v-if="modalModifiersExpand.multi_select" class="mt10 flex align-center" style="user-select: none; justify-content: flex-end">
						<button @click.stop="AddModifier(modifier, modalModifiersExpand, -1)" class="icon secondary sm"><i class="icon icon-minus"></i></button>
						<span style="display:inline-block; width: 40px;" class="bold ta-center count">{{modifier.count ? modifier.count : '0'}}</span>
						<button @click.stop="AddModifier(modifier, modalModifiersExpand, 1)" class="icon secondary sm"><i class="icon icon-plus"></i></button>
					</div>
				</div>

		</div>
		
		<button slot="actions" class="primary purple" @click="modalModifiersExpand=null">Aceptar</button>
		
	</modal>

	<modal v-model="modalReplacement" :title="modalReplacement?modalReplacement.group.title:'Reemplazar Modificador'" :dir="['right', 'right']" :modalClass="['fill', 'sm right h100']">
		
		<div class="section" v-if="modalReplacement">
			
			<div class="mb20">
				<div class="f18">{{modalReplacement.modifier.title}}</div>
			</div>

			<div :class="`mb10 selectable 
			item-modifier well pa10 ${!disableUnavailable || !modifier.unavailable ? 'available' : 'unavailable'}`"
			v-for="(modifier, index2) in modalReplacement.modifier.replacement_options"
			:key="index2"
			@click="SelectReplacement(modalReplacement.modifier, modalReplacement.group, modifier)">
				<div class="fill flex align-center">
					<div v-show="modifier.unavailable" :class="`check ${modifier.selected?'selected':''} ${disableUnavailable && modifier.unavailable?'unavailable':''} mr10`"></div>
					<div class="flex column flex-fill">
						<div class="title">{{modifier.title}}</div>
						<div class="secondary-text description">{{modifier.description}}</div>
					</div>
					<div v-if="!disableUnavailable || !modifier.unavailable" class="flex">
						<div v-if="modifier.price_reduction" class="cred line-through mr5">{{Util.Price(modifier.price_old)}}</div>
						<span class="item-tag mr5" v-if="modifier.price_label">{{modifier.price_label}}</span>
						<div>{{Util.Price(modifier.price)}}</div>
						<!--<div v-else class="secondary-text">Sin Costo</div>-->
					</div>
					<div v-else class="secondary-text">No disponible</div>
				</div>
			</div>

		</div>
		
		<button slot="actions" class="primary purple" @click="modalReplacement=null">Aceptar</button>
		
	</modal>

	<modal v-model="modalCombo" dir="right" :modalClass="['fill borderless', 'sm right h100']" :title="modalCombo ? modalCombo.title : ''">
		<div class="section" v-if="modalCombo">
			<div :class="`well selectable ${product.modifiers.length > 0 ? (ModifiersConditions(product) ? '' : 'cred') : ''} mb10 flex`" 
				v-for="(product, prodIndex) in modalCombo.products" :key="prodIndex" @click="modalModifiers=product">
				<div class="flex column flex-fill">
					<div v-if="product.info" class="secondary-text">{{product.info}}</div>
					<div class="bold">{{product.title}}</div>
					<ul>
                        <li :class="modifier.type" v-for="(modifier, indexMod) in currentCombo.display[prodIndex]" :key="`${prodIndex}-${indexMod}`">{{modifier.value}}</li>
                    </ul>
				</div>
				<div class="flex column" v-if="product.modifiers.length > 0">
					<div class="bold mb5" v-if="currentCombo.modifiers[prodIndex].price">{{Util.Price(currentCombo.modifiers[prodIndex].price)}}</div>
					<button :class="`secondary icon ${product.modifiers.length > 0 ? (ModifiersConditions(product) ? 'purple' : 'red') : ''}`">
						<i class="icon icon-arrow-right"></i>
					</button>
					
				</div>
			</div>
		</div>

		<div slot="actions" v-if="modalCombo" class="fill flex column">

			<input-field class="flex-fill mb10"
			v-if="!hideComment"
			v-model="modalCombo.comment"
			placeholder="Comentario (Opcional)">
			</input-field>

			<div class="flex fill align-center">
				
				<div class="flex flex-fill align-center" v-if="multiSelection">
					<i @click="AddQuantity(-1)" class="options icon-minus"></i>
					
					<input-field class="flex-fill mr5 ml5"
					v-model="quantity"
					@Enter="modalCombo.anticipation? confirmAnticipation = {combo: modalCombo, quantity} : AddCombo(modalCombo, quantity)">
					</input-field>
					
					<i @click="AddQuantity(1)" class="options icon-plus"></i>
				</div>
				<div v-else class="flex-fill"></div>

				<button :class="`ml10 primary ${total <= 0 || currentCombo.requires_modifiers ? 'error-color' : 'purple'}`" @click="modalCombo.anticipation? confirmAnticipation = {combo: modalCombo, quantity} : AddCombo(modalCombo, quantity)">Aceptar <span class="ml5" v-if="total > 0">{{Util.Price(total)}}</span></button>
				
			</div>
			
		</div>

		

	</modal>

	<modal v-model="confirmAnticipation" dir="center" :modalClass="['', 'sm']" title="Confirmar producto">
		<div v-if="confirmAnticipation">
			<div style="padding: 20px" v-if="confirmAnticipation.item">
                <div>{{confirmAnticipation.item.title}} requiere {{ confirmAnticipation.item.anticipation_time }} horas de preparación</div>
            </div>
            <div style="padding: 20px" v-if="confirmAnticipation.combo">
                <div>{{confirmAnticipation.combo.title}} requiere {{ confirmAnticipation.combo.anticipation_time }} horas de preparación</div>
            </div>
		</div>

		<button slot="actions" class="secondary" @click="confirmAnticipation = null">Cancelar</button>
		<button slot="actions" class="primary selected-color" @click="confirmAnticipation.item? AddItem(confirmAnticipation.item, confirmAnticipation.quantity) : AddCombo(confirmAnticipation.combo, confirmAnticipation.quantity);confirmAnticipation = null">Confirmar</button>

	</modal>

	<sku-reader @OnSKU="OnSKU"></sku-reader>

</div>
</template>

<script>
import SkuReader from './SkuReader.vue';
export default {
	components: {SkuReader},
	props: {
		value: {
			type: [Object, Array],
			default: () => {}
		},
		menu: {
			type: Array,
			default: () => []
		},
		itemType: {
			type: Number,
			default: null
		},
		showUnavailable: {
			type: Boolean,
			default: true
		},
		showSelected: {
			type: Boolean,
			default: true
		},
		multiSelection: {
			type: Boolean,
			default: true
		},
		selectModifiers: {
			type: Boolean,
			default: false
		},
		callbackOnly: {
			type: Boolean,
			default: false
		},
		filter: {
			type: Function,
			default: null
		},
		currentlySelected: {
			type: Object,
			default: null
		},
		disableUnavailable: {
			type: Boolean,
			default: false
		},
		showSearch: {
			type: Boolean,
			default: true
		},
		favorite: {
			type: Object,
			default: null
		},
		canClickItems: {
			type: Boolean,
			default: true
		},
		overwrites: {
			type: Array,
			default: null,
		},
		inventory: {
			type: [Object, Array],
			default: null
		},
		timestamp: {
			type: Number,
			default: null
		},
		modifiersValidation: {
			type: Boolean,
			default: true
		},
		bodyClass: {
			type: String,
			deafult: ''
		},
		hideComment: {
			type: Boolean,
			default: false,
		},
		hideQuantity: {
			type: Boolean,
			default: false,
		},
		typeSale: {
			type: String,
			deafult: null
		},
		orderedItems: {
			type: [Object, Array],
			default: null
		},
		positionSection: {
			type: String,
			deafult: null
		},
		showInfoItem: {
			type: Boolean,
			default : true
		},
		showColors: {
			type: Boolean,
			default : false
		},
		selectedPlatform: {
			type: String,
			deafult: ''
		},
	},
	data() {
		return {
			search: '',
			selecteditems: {},
			viewGrid: true,
			modalModifiers: null,
			modalQuantity: null,
			quantity: 0,
			searchModifier: '',
			itemsCount: 0,
			modalModifiersExpand: null,
			modalCombo: null,
			modalReplacement: null,
			confirmAnticipation: null,
			currentOverwrite: null,
			selectedSection : null
		}
	},
	mounted() {
		this.selecteditems = this.value == null ? {} : this.value;

		this.viewGrid = this.$root.platform == 'desktop' && localStorage.defaultItemsView === 'GRID';
		this.$root.$on('OnMobile', () => {
			this.viewGrid = false;
		});
		this.$root.$on('OnDesktop', () => {
			this.viewGrid = localStorage.defaultItemsView === 'GRID';
		});

		if(this.currentMenuOverwriteIndex)
		{
			this.currentOverwrite = this.overwrites[parseInt(this.currentMenuOverwriteIndex.split('INDEX:')[1])].overwrites;
		}

	},
	methods: {
		ClickItem(item, section) {

			if(!this.canClickItems) return;

			console.log(item);
			item = this.GetItemWithOverwrites(item);

			if(this.disableUnavailable && item.unavailable) return this.Info(`${item.title} no disponible`);
			this.searchModifier = '';

			var key = item.id;
			if(this.multiSelection && this.selecteditems[key])
				this.quantity = this.selecteditems[key].quantity;
			else
				this.quantity = 1;

			var itemData =JSON.parse(JSON.stringify(item));
			itemData.section = section;

			if(this.selectModifiers)
			{
				const modifyItem = (modal, item) => {
					if(modal.products && modal.products.length) {
						return modal.products.forEach((product, ind) => {
							modifyItem(modal.products[ind], this.Util.Copy(product));
						});
					}

					if (item.modifiers && item.modifiers.length) {
						item.modifiers.forEach((group, indexGroup) => {
							group.modifiers.forEach((modifier, indexModifier) => {
								if (modifier.auto_select && !group.replacement && !modifier.hide_pos && !modifier.unavailable) {
									if (group.multi_select) {
										if (modifier.auto_select > 0) {
											this.$set(modal.modifiers[indexGroup].modifiers[indexModifier], 'count', modifier.auto_select);
										}
									} else {
										this.$set(modal.modifiers[indexGroup].modifiers[indexModifier], 'selected', true);
									}
								}
							});
						});
					}
				}

				if(item.type == 1)
				{
					this.modalCombo = itemData;
					modifyItem(this.modalCombo, Util.Copy(itemData));
				}
				else
				{
					if(item.modifiers.length){
						this.modalModifiers = itemData;
						modifyItem(this.modalModifiers, Util.Copy(itemData));
					} else{
						this.modalQuantity = itemData;
					}
				}
			}
			else
			{
				this.$emit('OnClick', item);
				if(this.multiSelection)
					this.modalQuantity = itemData;
				else
					this.AddItem(item);
			}
		},
		AddItem(item, quantity, tag) {
			if((this.modalQuantity && this.modalQuantity.comment_required && !this.modalQuantity.comment) || (this.modalModifiers && this.modalModifiers.comment_required && !this.modalModifiers.comment)) return this.Info("El comentario es obligatorio");
			if(quantity == undefined) quantity = 1;
			if(tag == undefined) tag = '';
			if(isNaN(quantity) || quantity <= 0) return this.Info('Cantidad inválida.');

			if(item.max_quantity_enabled && quantity > item.max_quantity) return this.Info(`La cantidad máxima permitida de ${item.title} es ${item.max_quantity}`);

			//if(item.comment_required && !item.comment) return this.Info('Comentario obligatorio');
			if(this.selectModifiers && !this.ModifiersConditions())
			{
				for(var i = 0; i < this.$refs.modifierGroups.length; i++)
				{
					if(this.$refs.modifierGroups[i].childNodes[0].classList.contains('error-color'))
					{
						return scrollTo(this.$refs.modifierGroups[i], {elementToScroll: this.$refs.containerModifiers, maxDuration: 350}).then();
					}
				}
				return this.Info('No se cumplen las condiciones de modificadores');
			}
			//quantity = item.float_quantity ? parseFloat(quantity) : parseInt(quantity);
			quantity = item.float_quantity ? this.Util.RoundFloat(quantity) : parseInt(quantity);

			var key = item.id+''+tag;
			/*
			var modifiers = [];
			var modifiersPrice = 0;
			for(var i = 0; i < item.modifiers.length; i++)
			{
				var group = item.modifiers[i];
				for(var j = 0; j < group.modifiers.length; j++)
				{
					var modifier = group.modifiers[j];
					if(!group.multi_select)
					{
						if(modifier.selected)
						{
							modifiersPrice += parseInt(modifier.price);
							key += group.title + modifier.title
							modifiers.push({
								title: modifier.title,
								group: group.title,
								price: modifier.price,
								item: item.title
							});
						}	
					}
					else
					{
						if(modifier.count && modifier.count > 0)
						{
							for(var k = 0; k < modifier.count; k++)
							{
								modifiersPrice += parseInt(modifier.price);
								key += group.title + modifier.title
								modifiers.push({
									title: modifier.title,
									group: group.title,
									price: modifier.price,
									item: item.title
								});
							}
						}
					}
					
				}
			}
			*/

			var modifiersSummary = this.ItemModifiersSummary(item);
			var itemPrice = parseInt(item.price);
			var modifiersPrice = modifiersSummary.total_money;
			key += modifiersSummary.key;

			if(item.comment)
				key += '-'+item.comment;

			let section = this.currentMenu.find( section => section.title == item.section);

			var itemData = {
				id: item.id,
				item_price: itemPrice,
				price: itemPrice + modifiersPrice,
				title: item.title,
				modifiers: modifiersSummary.arr,
				quantity: quantity,
				total_money: parseInt((itemPrice + modifiersPrice) * quantity),
				categories: item.categories,
				autocomplete: item.autocomplete,
				comment: item.comment ? item.comment : null,
				section: item.section,
				float_quantity: item.float_quantity,
				float_quantity_measure: item.float_quantity_measure,
				tag: tag,
				key: key,
				image: item.image,
				section_hide_delivery: section.hide_delivery,
				section_hide_takeaway: section.hide_takeaway,
				hide_delivery: item.hide_delivery,
				hide_takeaway: item.hide_takeaway,
				anticipation: item.anticipation,
				anticipation_time: item.anticipation_time,
				max_quantity: item.max_quantity,
				max_quantity_enabled: item.max_quantity_enabled,
				timestamp: item.timestamp ? item.timestamp : this.Util.Timestamp(),
				id_local : item.id_local
			}
			if(item.price_overwrite || item.price_old) itemData.overwrite = true
			this.$emit('OnItemAdded', itemData);
			this.modalQuantity = null;
			this.modalModifiers = null;
			if(this.callbackOnly) return;

			var key = item.id;

			if(this.selecteditems[key])
			{
				if(this.multiSelection)
				{
					if(quantity <= 0)
						this.$delete(this.selecteditems, key);
					else
						this.$set(this.selecteditems[key], 'quantity', quantity);
				}
				else
					this.$delete(this.selecteditems, key);
			}
			else
			{
				if(quantity > 0)
				{
					this.$set(this.selecteditems, item.id, {
						id: key,
						title: item.title,
						quantity: quantity
					});
				}
			}
			
		},
		ShowItem(item)
		{
			if(!this.isEdit)
			{
				return this.selecteditems[item.type + '_' + item.id];
			}
			else
			{
				if(this.search === null || this.search.trim() == '') return true;
				
				var search = this.search.toUpperCase();
				if(item.title.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().indexOf(search) > -1) return true;
				if(item.description && item.description.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().indexOf(search) > -1) return true;
				return false;
			}
			
		},
		SelectModifier(modifier, group)
		{
			if(this.disableUnavailable && modifier.unavailable) return;

			if(modifier.selected)
			{
				this.$set(modifier, 'selected', false);
				this.$delete(modifier, 'replacement');
				return;
			}

			//grupo de reemplazo
			if(group.replacement)
			{
				this.modalReplacement = {modifier: modifier, group: group};
				return;
			}

			var count = 0;
			for(var i = 0; i < group.modifiers.length; i++)
				if(group.modifiers[i].selected) count++;

			var max = group.max === 0 ? 999 : group.max;

			if(count < max)
			{
				this.$set(modifier, 'selected', true);
			}
			else if(count == 1 && group.min <= 1 && max == 1)
			{
				for(var i = 0; i < group.modifiers.length; i++)
					this.$set(group.modifiers[i], 'selected', false);
				this.$set(modifier, 'selected', true);
			}
		},
		AddModifier(modifier, group, quantity)
		{
			if(this.disableUnavailable && modifier.unavailable) return;

			if(!group.multi_select) return;
			var mod_count = modifier.count ? modifier.count : 0;
			mod_count += quantity;
			if(mod_count < 0) mod_count = 0;

			var count = 0;
			for(var i = 0; i < group.modifiers.length; i++)
				count += group.modifiers[i].count ? group.modifiers[i].count : 0;

			var max = group.max === 0 ? 999 : group.max;

			if(count < max || quantity < 0)
			{
				this.$set(modifier, 'count', mod_count);
			}
			else if(group.min == 1 && max == 1 && count == 1 && quantity == 1)
			{
				for(var i = 0; i < group.modifiers.length; i++)
					this.$set(group.modifiers[i], 'count', 0);
				this.$set(modifier, 'count', 1);
			}
		},
		ModifierGroupConditions(group)
		{
			var count = 0;
			for(var i = 0; i < group.modifiers.length; i++)
			{
				if(!group.multi_select && group.modifiers[i].selected) count++;
				else if(group.multi_select && group.modifiers[i].count) count += group.modifiers[i].count;
			}
			
			var min = group.min;
			var max = group.max === 0 ? 999 : group.max;
			return count >= min && count <= max;
		},
		ModifiersConditions(item)
		{
			if(!this.modifiersValidation) return true;
			if(!item && this.modalModifiers) item = this.modalModifiers; 
			if(!item) return true;
			for(var i = 0; i < item.modifiers.length; i++)
				if(!this.ModifierGroupConditions(item.modifiers[i])) return false;
			return true;
		},
		GoToSection(section, offset)
		{
			if(this.positionSection && this.$refs.searchItemSelection) this.$refs.searchItemSelection.currentValue = '';
			this.selectedSection = section;
			this.$nextTick( () => {
				var elSection = this.$refs[`section-${section}`];
				if(!elSection || !elSection.length) return;
				elSection = elSection[0];
				var parent = elSection.closest('.ofy');
				parent = parent ? parent : this.$el;
		
				return scrollTo(elSection, {
					elementToScroll:  parent,
					maxDuration: 50,
					verticalOffset: offset == undefined ? -60 : offset
				}).then();
			})

		},
		Search(text)
		{
			this.search = text.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
		},
		ClickFirst() 
		{
			if(this.visibleItems.length > 0 && this.visibleItems[0].items.length > 0)
				this.ClickItem(this.visibleItems[0].items[0]);
		},
		SearchAddItem(item, openModal, addUnavailable, removeTags)
		{
			for(var i = 0; i < this.currentMenu.length; i++)
			{
				for(var j = 0; j < this.currentMenu[i].items.length; j++)
				{
					if(this.currentMenu[i].items[j].title == item.title)
					{
						var menuItem = this.GetItemWithOverwrites(this.currentMenu[i].items[j]); //JSON.parse(JSON.stringify(this.currentMenu[i].items[j]));
						menuItem.section = this.currentMenu[i].title;
						if(menuItem.unavailable && !addUnavailable) return false;

						function SelectItemModifiers(itemCopy, selectedMods)
						{
							var mods = {};
							for(var k = 0; k < selectedMods.length; k++)
							{
								var modKey = selectedMods[k].group + '_' + selectedMods[k].title;
								mods[modKey] = mods[modKey] ? mods[modKey]+1 : 1;
							}
							
							for(var z = 0; z < itemCopy.modifiers.length; z++)
							{
								var group =  itemCopy.modifiers[z];

								var groupReplacements = {};
								if(group.replacement)
								{
									group.modifiers_replacement.forEach(replacement => {
										groupReplacements[replacement.title] = replacement;
									});
								}

								group.selectedQuantity = 0;
								for(var x = 0; x < group.modifiers.length; x++)
								{
									var modifier = group.modifiers[x];
									//if(mods[`${group.title}_${modifier.title}`] && !item.unavailable)
									if(group.replacement)
									{
										group.modifiers_replacement.forEach(replacement => {
											if(modifier.unavailable && !addUnavailable) return;

											if(mods[`${group.title}_${modifier.title} > ${replacement.title}`])
											{
												if(modifier.count === undefined) modifier.count = 0;
												modifier.count++;
												group.selectedQuantity += modifier.count;
												modifier.selected = true;
												modifier.replacement = replacement;
											}
										});
									}
									else
									{
										if(modifier.unavailable && !addUnavailable) continue;

										if(mods[`${group.title}_${modifier.title}`]){
											if(!group.multi_select){
												if(modifier.count === undefined) modifier.count = 0;
												modifier.count++;
												group.selectedQuantity += modifier.count;
												modifier.selected = true;
											}
											else{
												group.selectedQuantity += mods[`${group.title}_${modifier.title}`];
												modifier.count = mods[`${group.title}_${modifier.title}`];
											}
										}
									}
										
								}
								console.log(group);
								if((group.min > 0 && group.selectedQuantity < group.min) || (group.max > 0 && group.selectedQuantity > group.max)) return true;
							}
						}

						if(menuItem.type == 0)
						{
							const deleteItem = SelectItemModifiers(menuItem, item.modifiers);
							if(deleteItem) return;
						}
						else
						{
							for(var k = 0; k < menuItem.products.length; k++)
							{
								if(k < item.modifiers.length)
								{
									const deleteItem = SelectItemModifiers(menuItem.products[k], item.modifiers[k].modifiers);
									if(deleteItem) return;
									if(item.modifiers[k].comment) menuItem.products[k].comment = item.modifiers[k].comment;
								}
							}
						}

						if(item.comment) menuItem.comment = item.comment;

						//Abre el modal para editar el item
						if(openModal)
						{
							if(item.quantity) this.quantity = item.quantity;
							if(menuItem.type == 1)
								this.modalCombo = menuItem;
							else if(menuItem.modifiers.length)
								this.modalModifiers = menuItem;
							else
								this.modalQuantity = menuItem;
						}
						else
						{
							var tag = '';
							if(!removeTags)
							{
								if(item.delivered) tag = 'delivered';
								else if(item.prepared) tag = 'prepared';
							}

							if(menuItem.type == 0)
								this.AddItem(menuItem, item.quantity, tag);
							else if(menuItem.type == 1)
								this.AddCombo(menuItem, item.quantity, tag);
						}

						return true;
					}
				}
			}
			return false;
		},
		PushItem(item, key)
		{
			if(item.type) return;
			var menuItem = this.indexItems[item.id];
			var tag = item.delivered ? 'delivered' : (item.prepared ? 'prepared' : '');
			var it = {
				id: item.id,
				title: item.title,
				price: item.price,
				modifiers: item.modifiers,
				quantity: item.quantity,
				total_money: item.total_money,
				autocomplete: item.autocomplete,
				comment: item.comment ? item.comment : null,
				section: item.section,
				float_quantity: menuItem ? menuItem.float_quantity : false,
				float_quantity_measure: menuItem ? menuItem.float_quantity_measure : '',
				tag: tag,
				key: key,
				timestamp: this.itemsCount,
				added_at : item.added_at,
				created_at : item.created_at
			};
			if(item.combo) it.combo = true;
			this.itemsCount++;
			this.$emit('OnItemAdded', it);
		},
		ToggleView() {
			this.viewGrid = !this.viewGrid;
		},
		/*
		Suma un valor a la variable quantity y redondea el resultado (debido al error de precision con decimales)
		@param value cantidad a agregar
		*/
		AddQuantity(value)
		{
			//this.quantity = (Math.round((this.quantity + value) * 1000)) / 1000;
			this.quantity = this.Util.RoundFloat(this.quantity + value);
		},
		CheckInventoryReferences(references)
		{
			if(!this.inventory) return true;
			
			for(var i = 0; i < references.length; i++)
			{
				var ref = references[i];
				if(ref.required && ref.inventory_item in this.inventory)
				{
					var inventory = this.inventory[ref.inventory_item];
					if(inventory.subitems && inventory.subitems.length > 0){
						//Tiene subítems
						let subitems = inventory.subitems;
						for(var j = 0; j < subitems.length; j++)
						{
							let subitem = this.inventory[subitems[j].title];
							if(!subitem) return false;
							let subitemPerformance = subitem.performance ? (subitem.performance/100) : 1;
							let subitemCurrentQuantity = subitem.quantity * subitemPerformance;
							let subitemRequiredQuantity = ref.quantity * subitems[j].quantity;
							if(subitemRequiredQuantity > subitemCurrentQuantity) return false;
						}
					}else{
						var performance = ref.bypass_performance ? 1 : (inventory.performance ? (inventory.performance/100) : 1);
						var currentQuantity = inventory.quantity * performance;
						var requiredQuantity = ref.quantity;
						if(requiredQuantity > currentQuantity) return false;
					}
				}
			}
			return true;
		},
		ItemModifiersSummary(item)
		{
			var ret = [];
			var total = 0;
			var key = '';

			for(var i = 0; i < item.modifiers.length; i++)
			{
				var group = item.modifiers[i];
				
				for(var j = 0; j < group.modifiers.length; j++)
				{
					var modifier = group.modifiers[j];
					if(!group.multi_select)
					{
						if(modifier.selected)
						{
							if(group.replacement)
							{
								total += modifier.price;
								total += modifier.replacement.price;
								key += group.title + modifier.title + '>' + modifier.replacement.title;
								let data = {
									title: `${modifier.title} > ${modifier.replacement.title}`, 
									price: modifier.price+modifier.replacement.price, 
									group: group.title,
									modifier_original: modifier.title,
									modifier_replacement: modifier.replacement.title,
									modifier_original_price: modifier.price,
									modifier_replacement_price: modifier.replacement.price,
									item: item.title,
									id_item: item.id
								}
								if(modifier.price_old || modifier.price_overwrite) data.overwrite = true
								ret.push(data);
							}
							else
							{
								total += modifier.price;
								key += group.title + modifier.title
								let data = {
									title: modifier.title, 
									price: modifier.price, 
									group: group.title,
									item: item.title,
									id_item: item.id
								}
								if(modifier.price_old || modifier.price_overwrite) data.overwrite = true
								ret.push(data);
							}
						}
					}
					else
					{
						if(modifier.count && modifier.count > 0)
						{
							for(var k = 0; k < modifier.count; k++)
							{
								key += group.title + modifier.title
								let data = {
									title: modifier.title, 
									price: modifier.price, 
									group: group.title,
									item: item.title,
									id_item: item.id
								}
								if(modifier.price_old || modifier.price_overwrite) data.overwrite = true
								ret.push(data);
							}
							total += (modifier.price * modifier.count);
						}
					}
					
				}

			}
			return {
				key: key,
				arr: ret,
				total_money: parseInt(total),
				display: this.Util.ModifiersList({modifiers: ret, comment: item.comment})
			};
		},
		AddCombo(item, quantity, tag) {
			if(quantity == undefined) quantity = 1;
			if(tag == undefined) tag = '';
			if(isNaN(quantity) || quantity <= 0) return this.Info('Cantidad inválida.');

			if(item.max_quantity_enabled && quantity > item.max_quantity) return this.Info(`La cantidad máxima permitida de ${item.title} es ${item.max_quantity}`);

			var combo = this.currentCombo ? this.currentCombo : this.ComboToItem(item, quantity);

			if(combo.requires_modifiers)
				return this.Info('No se cumplen las condiciones de modificadores');
			
			var key = item.id+''+tag+combo.key;
			
			if(item.comment)
				key += '-'+item.comment;
			
			const sectionMap = new Map();

			Util.Copy(this.menu).forEach(section => {
				section.items.forEach(item => {
					if (!sectionMap.has(item.title)) {
						sectionMap.set(item.title, {
							hide_delivery: section.hide_delivery,
							hide_takeaway: section.hide_takeaway
						});
					}
				});
			});

			item.products.forEach( (subitem, indexSubitem) => {
				const sectionInfo = sectionMap.get(subitem.title);
				item.products[indexSubitem].section_hide_delivery = sectionInfo.hide_delivery;
				item.products[indexSubitem].section_hide_takeaway = sectionInfo.hide_takeaway;
			});

			var itemData = {
				id: item.id,
				item_price: item.price,
				price: combo.price,
				title: item.title,
				modifiers: combo.modifiers,
				quantity: quantity,
				total_money: combo.total,
				categories: combo.categories,
				autocomplete: combo.autocomplete,
				comment: item.comment ? item.comment : null,
				section: item.section,
				float_quantity: false,
				tag: tag,
				key: key,
				combo: true,
				subitems: item.products,
				image: item.image,
				anticipation: item.anticipation,
				anticipation_time: item.anticipation_time,
				max_quantity: item.max_quantity,
				max_quantity_enabled: item.max_quantity_enabled,
				timestamp: item.timestamp ? item.timestamp : this.Util.Timestamp()
			}

			this.$emit('OnItemAdded', itemData);
			this.modalQuantity = null;
			this.modalModifiers = null;
			this.modalCombo = null;
			if(this.callbackOnly) return;

			var key = item.id;

			if(this.selecteditems[key])
			{
				if(this.multiSelection)
				{
					if(quantity <= 0)
						this.$delete(this.selecteditems, key);
					else
						this.$set(this.selecteditems[key], 'quantity', quantity);
				}
				else
					this.$delete(this.selecteditems, key);
			}
			else
			{
				if(quantity > 0)
				{
					this.$set(this.selecteditems, item.id, {
						id: key,
						title: item.title,
						quantity: quantity
					});
				}
			}
			
		},
		SelectReplacement(modifier, group, replacement)
		{
			if(this.disableUnavailable && replacement.unavailable) return;

			var count = 0;
			for(var i = 0; i < group.modifiers.length; i++)
				if(group.modifiers[i].selected) count++;

			var max = group.max === 0 ? 999 : group.max;

			if(count < max)
			{
				this.$set(modifier, 'selected', true);
				this.$set(modifier, 'replacement', replacement);
			}
			else if(count == 1 && group.min <= 1 && max == 1)
			{
				for(var i = 0; i < group.modifiers.length; i++)
				{
					this.$set(group.modifiers[i], 'selected', false);
					this.$delete(group.modifiers[i], 'replacement');
				}
				this.$set(modifier, 'selected', true);
				this.$set(modifier, 'replacement', replacement);
			}

			this.modalReplacement = null;

		},
		ApplyOverwrite(subject, key, overwrites)
		{
			if(`${key}-overwrite` in overwrites)
			{
				var overwriteType = overwrites[`${key}-overwrite`];
				var overwriteValue = overwrites[`${key}-value`];
				
				if(overwriteType == 'PRICE')
				{
					subject.price = overwriteValue;
					subject.price_overwrite = true;
				}
				else if(overwriteType == 'PRICE_REDUCTION')
				{
					subject.price_old = subject.price;
					subject.price = overwriteValue;
					subject.price_reduction = true;
				}
				else if(overwriteType == 'UNAVAILABLE')
				{
					subject.unavailable = true;
					subject.unavailable_overwrite = true;
				}
				else if(overwriteType == 'DISCOUNT')
				{
					subject.price_old = subject.price;
					subject.price_label = `-${overwriteValue}%`;
					subject.price = Math.max(0, subject.price - parseInt(subject.price*overwriteValue/100));
				}
				/*
				else if(overwriteType == '2x1')
				{
					subject.price_label = '2x1';
				}
				else if(overwriteType == '3x2')
				{
					subject.price_label = '3x2';
				}
				*/
			} 
		},
		OnSKU(code)
		{
			if(!this.Local.modules.module_barcode) return;
			//console.log(e);
			
			if(this.skuItems[code])
			{
				var item = this.skuItems[code];
				if(!item.unavailable)
				{
					console.log(this.skuItems[code]);

					var itemData = {
						id: item.id,
						item_price: item.price,
						price: item.price,
						title: item.title,
						modifiers: [],
						quantity: 1,
						total_money: item.price,
						categories: item.categories,
						autocomplete: item.autocomplete,
						comment: null,
						section: item.section,
						float_quantity: item.float_quantity,
						float_quantity_measure: item.float_quantity_measure,
						tag: '',
						key: item.id.toString(),
						timestamp: item.timestamp ? item.timestamp : this.Util.Timestamp(),
						scanned: true,
						image: item.image,
					}

					this.$emit('OnItemAdded', itemData);
					this.Toast({text: item.title, override: true});
				}

			}
		},
		ComboToItem(combo, quantity)
		{
			var ret = {
				modifiers: [],
				total_modifiers: 0,
				key: '',
				display: [],
				price: combo.price,
				total: 0,
				requires_modifiers: false,
				autocomplete: combo.autocomplete,
				categories: []
			};
			
			var subitemIndex = 0;
			combo.products.forEach(item => {
				var itemAsModifier = {
					title: item.title,
					group: item.title,
					price: 0,
					item: item.title,
					subitem: subitemIndex++,
					modifiers: [],
					id_item: item.id,
					comment: item.comment
				};
				ret.key += `${item.id}-`;
				
				if(!this.ModifiersConditions(item)) ret.requires_modifiers = true;

				var modifiersSummary = this.ItemModifiersSummary(item);
				
				itemAsModifier.modifiers = modifiersSummary.arr;
				itemAsModifier.price = modifiersSummary.total_money;
				ret.key += modifiersSummary.key;
				if(item.comment) ret.key += '-'+item.comment;
				ret.modifiers.push(itemAsModifier);
				ret.total_modifiers += modifiersSummary.total_money;
				ret.display.push(modifiersSummary.display);
			});
			ret.price += ret.total_modifiers;
			ret.total = ret.price * quantity;
			return ret;
		},
		GetItemWithOverwrites(refItem)
		{
			let item = this.Util.Copy(this.indexItems[refItem.id]);
			let overwrites = this.currentOverwrite;
	
			item.section = refItem.section;

			if(!this.CheckInventoryReferences(item.inventory_references))
			{
				item.unavailable = true;
				item.unavailable_stock = true;
			}

			if(overwrites) this.ApplyOverwrite(item, `${item.id}`, overwrites);

			for(var k = 0; k < item.modifiers.length; k++)
			{
				var group = item.modifiers[k];
				for(var y = 0; y < group.modifiers.length; y++)
				{
					var modifier = group.modifiers[y];
					if(!this.CheckInventoryReferences(modifier.inventory_references))
					{
						modifier.unavailable = true;
						modifier.unavailable_stock = true;
					}

					if(overwrites) this.ApplyOverwrite(modifier, `${item.id}-${group.title}-${modifier.title}`, overwrites);

					if(group.replacement && modifier.replacement_options)
					{
						modifier.replacement_options.forEach(replacement => {
							if(!this.CheckInventoryReferences(replacement.inventory_references))
							{
								replacement.unavailable = true;
								replacement.unavailable_stock = true;
							}

							if(overwrites) this.ApplyOverwrite(replacement, `${item.id}-${group.title}-${replacement.title}-r`, overwrites);
						});
					}

				}

			}

	
			if(item.type == 1)
			{
				for(var k = 0; k < item.products.length; k++)
				{
					var subItem = item.products[k];
					if(!this.CheckInventoryReferences(subItem.inventory_references))
					{
						item.unavailable = true;
						item.unavailable_stock = true;
					}

					if(overwrites) 
					{
						if(`${subItem.id}-overwrite` in overwrites && overwrites[`${subItem.id}-overwrite`] == 'UNAVAILABLE')
						{
							item.unavailable = true;
							item.unavailable_overwrite = true;
						} 
					}
					
					subItem.modifiers.forEach(group => {
						group.modifiers.forEach(modifier => {
							if(!this.CheckInventoryReferences(modifier.inventory_references))
							{
								modifier.unavailable = true;
								modifier.unavailable_stock = true;
							}

							if(overwrites) this.ApplyOverwrite(modifier, `${subItem.id}-${group.title}-${modifier.title}`, overwrites);

							if(group.replacement && modifier.replacement_options)
							{
								modifier.replacement_options.forEach(replacement => {
									if(!this.CheckInventoryReferences(replacement.inventory_references))
									{
										replacement.unavailable = true;
										replacement.unavailable_stock = true;
									}

									if(overwrites) this.ApplyOverwrite(replacement, `${subItem.id}-${group.title}-${replacement.title}-r`, overwrites);
								});
							}
							
						})
					})
					
				}
			}

			return item;
			
		},
		BackToSections(offset) {
			if(this.positionSection && this.$refs.searchItemSelection) this.$refs.searchItemSelection.currentValue = '';
			this.selectedSection = null
			this.$nextTick(() => {
				const elSection = this.$refs.sectionSections;

				if (!elSection) return; // Verificar que la referencia existe.

				var parent = elSection.closest('.ofy');
				parent = parent ? parent : this.$el;
				
				scrollTo(elSection, {
					elementToScroll: parent,
					maxDuration: 50, // Más perceptible para el usuario.
					verticalOffset: offset === undefined ? -60 : offset,
				});
			});
		},
	},
	computed: {
		baseMenu() {
			//menu sin modificadores para que sea mas liviano en currentMenu
			if(!this.menu) return null;
			let tmpMenu = this.Util.Copy(this.menu);
			tmpMenu.forEach(section => {
				section.items.forEach(group => {
					group.modifiers = [];
				});
			});
			return tmpMenu;
		},
		currentMenu() {
			if(!this.menu) return [];

			let tmpMenu = Util.Copy(this.baseMenu);

			if(this.typeSale){
				const sectionMap = new Map();

				this.menu.forEach(section => {
					section.items.forEach(item => {
						if (!sectionMap.has(item.title)) {
							sectionMap.set(item.title, {
								hide_delivery: section.hide_delivery,
								hide_takeaway: section.hide_takeaway
							});
						}
					});
				});

				const typeKey = this.typeSale == 'DELIVERY'? 'hide_delivery' : 'hide_takeaway';

				const filteredSections = tmpMenu.filter( section => !section[typeKey]);
				filteredSections.forEach(section =>{
					section.items = section.items.filter(item => {
						if(item.type == 0){
							return !item[typeKey] && (!item.anticipation || this.typeSale !== 'SERVE'); //El servicio es distinto a SERVE o no tiene anticipación
						}else{
							const countSubitems = item.products;
							item.products = item.products.filter(subitem => {
								const sectionInfo = sectionMap.get(subitem.title);
								return sectionInfo && !sectionInfo[typeKey] && !subitem[typeKey] && (!subitem.anticipation || this.typeSale !== 'SERVE');
							});
							return item.products.length == countSubitems.length;
						}
					});
				});

				tmpMenu = filteredSections;
			}

			//var overwrites = null;
			//if(this.currentMenuOverwriteIndex != null) overwrites = this.overwrites[parseInt(this.currentMenuOverwriteIndex.split('INDEX:')[1])].overwrites;
			var overwrites = this.currentOverwrite;
	
			var ret = tmpMenu;
			for(var i = 0; i < ret.length; i++)
			{
				var section = ret[i];
				let countUnavailable = 0;
				for(var j = 0; j < section.items.length; j++)
				{
					var item = section.items[j];
					item.section = section.title;

					if(!this.CheckInventoryReferences(item.inventory_references))
					{
						item.unavailable = true;
						item.unavailable_stock = true;
					}

					if(overwrites) this.ApplyOverwrite(item, `${item.id}`, overwrites);
					if(item.unavailable) countUnavailable++;
					/*
					for(var k = 0; k < item.modifiers.length; k++)
					{
						var group = item.modifiers[k];
						for(var y = 0; y < group.modifiers.length; y++)
						{
							var modifier = group.modifiers[y];
							if(!this.CheckInventoryReferences(modifier.inventory_references))
							{
								modifier.unavailable = true;
								modifier.unavailable_stock = true;
							}

							if(overwrites) this.ApplyOverwrite(modifier, `${item.id}-${group.title}-${modifier.title}`, overwrites);

							if(group.replacement && modifier.replacement_options)
							{
								modifier.replacement_options.forEach(replacement => {
									if(!this.CheckInventoryReferences(replacement.inventory_references))
									{
										replacement.unavailable = true;
										replacement.unavailable_stock = true;
									}

									if(overwrites) this.ApplyOverwrite(replacement, `${item.id}-${group.title}-${replacement.title}-r`, overwrites);
								});
							}

						}

					}

			
					if(item.type == 1)
					{
						for(var k = 0; k < item.products.length; k++)
						{
							var subItem = item.products[k];
							if(!this.CheckInventoryReferences(subItem.inventory_references))
							{
								item.unavailable = true;
								item.unavailable_stock = true;
							}

							if(overwrites) 
							{
								if(`${subItem.id}-overwrite` in overwrites && overwrites[`${subItem.id}-overwrite`] == 'UNAVAILABLE')
								{
									item.unavailable = true;
									item.unavailable_overwrite = true;
								} 
							}
							
							subItem.modifiers.forEach(group => {
								group.modifiers.forEach(modifier => {
									if(!this.CheckInventoryReferences(modifier.inventory_references))
									{
										modifier.unavailable = true;
										modifier.unavailable_stock = true;
									}

									if(overwrites) this.ApplyOverwrite(modifier, `${subItem.id}-${group.title}-${modifier.title}`, overwrites);

									if(group.replacement && modifier.replacement_options)
									{
										modifier.replacement_options.forEach(replacement => {
											if(!this.CheckInventoryReferences(replacement.inventory_references))
											{
												replacement.unavailable = true;
												replacement.unavailable_stock = true;
											}

											if(overwrites) this.ApplyOverwrite(replacement, `${subItem.id}-${group.title}-${replacement.title}-r`, overwrites);
										});
									}
									
								})
							})
							
						}
					}
					*/

				}

				if(countUnavailable == section.items.length) section.unavailable = true;
			}

			if(this.selectedPlatform != 'SCHEDULE') ret = ret.filter(section => !section.unavailable);
			return ret;
		},
		indexItems() {
			var ret = {};
			this.menu.forEach(section => {
				section.items.forEach(item => {
					ret[item.id] = item;
				});
			});
			return ret;
		},
		skuItems() {
			var ret = {};
			this.currentMenu.forEach(section => {
				section.items.forEach(item => {
					if(item.sku) ret[item.sku] = item;
				});
			});
			return ret;
		},
		visibleItems() {
			if(!this.currentMenu) return [];
			var ret = [];

			for(var i = 0; i < this.currentMenu.length; i++)
			{
				var section = this.currentMenu[i];
				var sect = {
					title: section.title,
					hide_delivery: section.hide_delivery,
					hide_takeaway: section.hide_takeaway,
					items: [],
					color : section.color
				};
				for(var j = 0; j < section.items.length; j++)
				{
					var item = section.items[j];
					var addItem = true;
					if(this.search !== null && this.search.trim() !== '')
					{
						if(this.search.substr(0, 1) === '*')
						{
							var id = parseInt(this.search.replace('*', ''));
							if(item.id != id) addItem = false;
						}
						else
						{
							var search = this.search.trim().toUpperCase();
							var match = false
							if(item.title.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().indexOf(search) > -1) match = true;
							if(item.description && item.description.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().indexOf(search) > -1) match = true;
							if(!match) addItem = false;
						}
					}
					if(this.itemType !== null)
					{
						if(item.type !== this.itemType) addItem = false;
					}

					if(addItem && (!this.disableUnavailable || !item.unavailable || !this.selectedPlatform || this.selectedPlatform == 'SCHEDULE'))
						sect.items.push(item);
					
				}

				if(sect.items.length > 0)
					ret.push(sect);
			}

			if(this.positionSection == 'VERTICAL' && this.selectedSection){
				let select = ret.filter(fil => fil.title == this.selectedSection)
				ret = select
			}else if(this.positionSection == 'VERTICAL' && !this.selectedSection && (this.search === null || this.search.trim() === '')) {
				ret = []
			}

			return ret;
		},
		selectedInfo() {
			var arr = this.selecteditems ? Object.values(this.selecteditems) :[];
			var count = 0;
			for(var i = 0; i < arr.length; i++)
				count += arr[i].quantity;
			return {
				arr: arr,
				count: count
			}
		},
		selectedModifiers() {
			if(!this.modalModifiers) return {total_money: 0, arr: [], display: []};
			return this.ItemModifiersSummary(this.modalModifiers);
		},
		total() {
			if(this.modalCombo) return this.currentCombo.total;
			
			if(!this.currentItem) return 0;
			var quantity = this.currentItem.float_quantity ? parseFloat(this.quantity) : parseInt(this.quantity);
			if(isNaN(quantity)) return 0;
			
			if(this.modalQuantity)
				return this.modalQuantity.price * quantity;
			else if(this.modalModifiers)
				return (this.modalModifiers.price + this.selectedModifiers.total_money) * quantity;
			else
				return 0;
		},
		modifiersFilter() {
			if(!this.modalModifiers) return null;
			if(!this.searchModifier) return null;
			var ret = {};
			this.modalModifiers.modifiers.forEach(group => {
				var mods = {};
				group.modifiers.forEach(modifier => {
					if(this.Util.InString(modifier.title, this.searchModifier))
						mods[modifier.title] = true;
				});
				if(Object.values(mods).length > 0)
					ret[group.title] = mods;
			});
			return ret;
		},
		/*
		@return MenuItem el item actualmente seleccionado para ingresar modificadores o cantidad
		*/
		currentItem() {
			if(this.modalQuantity) return this.modalQuantity;
			if(this.modalModifiers) return this.modalModifiers;
			return null;
		},

		//verifica si hay algun sobrescritura de menu segun horario
		//nota: de deja como string en vez de objeto para que no se ejecute cambios en el watch
		currentMenuOverwriteIndex() {
			if(!this.overwrites || this.overwrites.length == 0 || !this.timestamp) return null;
			let moment = require('moment');
            let now = moment().unix();

			var day = this.Util.Date(this.timestamp, 'dddd', 'en').toLowerCase();
			var time = this.Util.Date(this.timestamp, 'HH:mm');
			var minutes = this.Util.TimeToMinutes(time);			
			var ret = null;
			for(var i = 0; i < this.overwrites.length; i++)
			{
				if(this.overwrites[i].type != 'SCHEDULE') return this.overwrites[i].type + ' - INDEX: ' + i;
				var schedules = this.overwrites[i].schedule;
				for(var j = 0; j < schedules.length; j++)
				{
					var schedule = schedules[j];
					var start = this.Util.TimeToMinutes(schedule.start);
					var finish = this.Util.TimeToMinutes(schedule.finish);
					let current = (!this.overwrites[i].start || this.overwrites[i].start <= now) && (!this.overwrites[i].finish || now <= this.overwrites[i].finish);

					if(schedule.day == day && minutes >= start && minutes <= finish && current)
					{
						//ret = this.overwrites[i];
						return 'SCHEDULE - INDEX: ' + i;
					}
				}
			}
			
			return ret;
		},

		currentCombo() {
			if(!this.modalCombo) return null;
			return this.ComboToItem(this.modalCombo, this.quantity);
		},
		orderGroupById(){
            if(!this.orderedItems || !this.orderedItems.length) return;
			const groupedData = {};

			this.orderedItems.forEach( item => {
				const id = item.id;
				const quantity = item.quantity;

				if (!groupedData[id]) {
                    groupedData[id] = 0;
                }

				groupedData[id] += quantity;
			});

			return groupedData;
		},
		filteredModifiers() {
			if (!this.modalModifiersExpand) return [];
			if (this.selectedPlatform == 'SCHEDULE') return this.modalModifiersExpand.modifiers;
			return this.modalModifiersExpand.modifiers.filter(
				(modifier) => !this.disableUnavailable || !modifier.unavailable
			);
		},
	},
	watch: {
		value: function(n, o)
		{
			this.selecteditems = n == null ? {} : n;
		},
		selecteditems: function(n, o) {
			this.$emit('input', this.selecteditems);
		},
		currentMenuOverwriteIndex: function(n, o) {
			if(o !== null)
				this.Toast('El menú ha cambiado');
			if(n !== null) this.currentOverwrite = this.overwrites[parseInt(n.split('INDEX:')[1])].overwrites;
			else this.currentOverwrite = null;
		},
		modalQuantity: function(n, o) {
			if(!n) this.$emit('OnSelectionClosed');
		},
		modalModifiers: function(n, o) {
			if(!this.modalCombo && !n) this.$emit('OnSelectionClosed');
		},
		modalCombo: function(n, o) {
			if(!n) this.$emit('OnSelectionClosed');
		},
		currentMenu: function(n, o) {
			console.log("menu rebuild");
		},
		baseMenu: function(n, o) {
			console.log("base menu rebuild");
		},
	},
}
</script>

<style lang="scss">
.item-selection {
	border-radius: 5px;
	box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.2);
	width: 120px; 
	height: 170px; 
	margin: 5px;
	transition: box-shadow 0.1s linear;
	flex-direction: column;
}
.item-selection:hover {
	box-shadow: 0px 3px 10px 0px rgba(0,181, 255,1);
}

.item-image {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	width: 120px; 
	height: 120px; 
	background-color: var(--gray-3);
	overflow: hidden;
}
.item-remove {
	height: 50px; 
	width: 120px; 
	bottom: 0px; 
	font-size: 30px; 
	background-color: var(--white);
}
.item-grid {
	padding: 0px !important;
	width: 120px;
	height: 180px;
	margin: 5px 5px;
	flex-direction: column;
	transition: 0.1s transform, box-shadow ease-in-out;
}
.item-grid div:nth-child(2) {
	text-align: center;
	padding: 5px;
	font-size: 12px;
	line-height: 1;
}
.item-grid div:nth-child(3) {
	text-align: center;
	padding-bottom: 5px;
}
.item-grid.selectable:hover {
	transform: scale(1.05);
	outline: 2px solid var(--gray-purple);
}
.item-list {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 10px;
}
.item-list .item-image {
	width: 50px !important;
	height: 50px !important;
	border-radius: 50%;
	overflow: hidden;
}
.item-list .item-remove {
	top: 0px;
	right: 0px;
	height: 100%;
	background-color: transparent;
	width: 55px;
}

.selection-counter {
	position: absolute;
	color: var(--white);
	font-weight: bold !important;
	font-size: 14px !important;
	background-color: var(--purple);
	left: 0px;
	top: 0px;
	padding: 5px 15px;
	border-radius: 10px 0px 10px 0px;
}
.fav-item {
	position: absolute;
	color: var(--white);
	right: 2px;
	bottom: -2px;
	text-shadow: 0px 0px 3px black;
}
.item-grid .fav-item {
	font-size: 20px;
	right: 6px;
}

.quantity-description {
	font-style: normal;
	font-size: 12px;
	width: 100%;
	text-align: center;
	font-weight: bold;
	color: var(--gray-2);
}
.menu-sections-toolbar {
	font-size: 14px;
	width: 100%;
	min-width: 100%;
	max-width: 100%;
	overflow-x: auto;
	flex-shrink: 0;
	position: sticky;
	z-index: 2;
	top: 0;
	overflow-y: hidden;
}
.menu-sections-toolbar .section {
	display: flex;
	padding: 0px 10px;
	overflow-x: auto;
	overflow-y: hidden;
}
.menu-sections-toolbar .section .input-style {
	flex-shrink: 0;
	margin: 0px 10px 0px 0px;
	padding: 0px 10px;
}
.menu-sections-toolbar .section-entry {
	padding: 8px 10px;
}

.modifiers-group-expand {
	cursor: pointer;
	border-radius: 10px;

	&:hover {
		background-color: var(--gray-3);
	}

}

.item-tag {
	background-color: var(--red);
	color: var(--white);
	font-size: 11px;
	padding: 2px 4px;
	border-radius: 10px;
}

.item-modifier {

	border-radius: 10px;

	&.available:hover {
		background-color: var(--gray-3);
	}

	&.unavailable {
		cursor: not-allowed !important;
		
		.description {
			display: none;
		}

		.title {
			color: var(--gray-1);
		}

		.count {
			color: var(--gray-2) !important;
		}

	}

	&.selected {
		.title, .count {
			color: var(--purple);
		}
	}

	&.unselected {
		.count {
			color: var(--gray-1);
		}
	}

}

.input-popover.float-quantity {

	&.tooltip {
        margin-bottom: 55px !important;
		&.left {
			margin-left: -15px !important;
		}
        &.right {
			margin-right: -15px !important;
		}
		&.mb {
			//margin-bottom: -1px !important;
			margin-bottom: 53px !important;
		}
    }
    
    .tooltip-inner
    {
        margin: 0;
        background-color: transparent !important;
        padding: 0 !important;
    }
    .tooltip-arrow {
        display: none;
    }
}

.mod-available:hover {
	background-color: var(--gray-3);
	border-radius: 10px;
}

@media (max-width: 820px) {
	.menu-sections-toolbar .section .input-style {
		border: none;
		margin: 0px;
	}
}

.tag-reservation {
	position: absolute;
	
}
.item-grid {
	.tag-reservation {
		border-radius: 10px;
		background-color: inherit;
		bottom: 5px;
		left: 50%;
		transform: translateX(-50%);
		padding: 5px 15px !important;
	}
}
.item-list {
	.tag-reservation {
		width: 100%;
		bottom: -18px;
		font-size: 10px;
		left: -5px;
	}
}

.dark {
	.item-grid.selectable:hover {
		outline: 2px solid var(--purple);
	}
	.item-list.selectable:hover {
		outline: 2px solid var(--purple);
	}
	.item-image {
		background-color: var(--dark-bg-0);
	}
	.item-modifier {
		&.available:hover {
			background-color: var(--dark-bg-3);
			box-shadow: -5px 0px 0px 0px  var(--dark-bg-3), 5px 0px 0px 0px  var(--dark-bg-3);
		}

		&.unavailable {
			
			.title {
				color: var(--dark-fc-3);
			}

			.count {
				color: var(--dark-fc-3) !important;
			}

		}

		&.selected {
			.title, .count {
				color: var(--cyan);
			}
		}

		&.unselected {
			.count {
				color: var(--dark-fc-2);
			}
		}

	}
	.modifiers-group-expand {
		&:hover {
			background-color: var(--dark-bg-0);
			box-shadow: -5px 0px 0px 0px  var(--dark-bg-0), 5px 0px 0px 0px  var(--dark-bg-0);
		}
	}
}
</style>